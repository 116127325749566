import Layout from "../components/Layout"
import ProductPage from "../components/products/ProductPage"
import React, { useEffect } from "react"
import useGroqResults from "../hooks/useGroqResults"
import { trackPageType } from "../lib/tracking/tracking"
import SEO from "../components/SEO"
import { gatsbyTemplateTypes } from "../lib/propTypes"
import { blocksToExcerpt } from "../lib/sanityUtil"

const ProductPageTemplate = ({ location, pageContext: { query } }) => {
  const [product] = useGroqResults(query)

  useEffect(() => {
    trackPageType("products")
  }, [product.path.current])

  return (
    <Layout product={product} title={product.title}>
      <SEO
        seo={product.seo}
        description={
          product.title +
          " - " +
          blocksToExcerpt(product.descriptions?.[0]?.content)
        }
        imageUrl={product.plpImage?.url}
        pathname={location.pathname}
        type="product"
      />
      <ProductPage key={product._id} product={product} />
    </Layout>
  )
}

ProductPageTemplate.propTypes = gatsbyTemplateTypes

export default ProductPageTemplate
